
import { defineComponent, useFetch } from '@nuxtjs/composition-api';
import { usePage, usePagePreloader } from '~/composables';

export default defineComponent({
  setup() {
    const { page, fetchPage } = usePage();
    const pagePreloader = usePagePreloader();

    useFetch(async () => {
      pagePreloader.start();
      await fetchPage('main');
      pagePreloader.stop();
    });

    return {
      page,
    };
  },
  head: {},
});
